


export const layouts = {
'agents/AppTemplate': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/AppTemplate.vue'),
'schoolmen/AppTemplate': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/AppTemplate.vue'),
'agents/layouts/AppNavbar': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/layouts/AppNavbar.vue'),
'agents/layouts/default': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/layouts/default.vue'),
'agents/components/AccountCard': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/AccountCard.vue'),
'agents/components/BlankModal': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/BlankModal.vue'),
'agents/components/ButtonBack': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/ButtonBack.vue'),
'agents/components/Chart': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/Chart.vue'),
'agents/components/Empty': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/Empty.vue'),
'agents/components/Link': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/Link.vue'),
'agents/components/LinkDelete': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/LinkDelete.vue'),
'agents/components/LinkEdit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/LinkEdit.vue'),
'agents/components/LinkShow': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/LinkShow.vue'),
'agents/components/RLink': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/RLink.vue'),
'agents/pages/error': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/error.vue'),
'agents/pages/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/index.vue'),
'agents/pages/login': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/login.vue'),
'schoolmen/layouts/SchoolHeader': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/layouts/SchoolHeader.vue'),
'schoolmen/layouts/blank': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/layouts/blank.vue'),
'schoolmen/layouts/default': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/layouts/default.vue'),
'schoolmen/components/AccountCard': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/AccountCard.vue'),
'schoolmen/components/BlankModal': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/BlankModal.vue'),
'schoolmen/components/ButtonBack': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/ButtonBack.vue'),
'schoolmen/components/Chart': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/Chart.vue'),
'schoolmen/components/Empty': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/Empty.vue'),
'schoolmen/components/Link': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/Link.vue'),
'schoolmen/components/LinkDelete': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/LinkDelete.vue'),
'schoolmen/components/LinkEdit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/LinkEdit.vue'),
'schoolmen/components/LinkShow': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/LinkShow.vue'),
'schoolmen/components/RLink': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/RLink.vue'),
'schoolmen/pages/error': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/error.vue'),
'schoolmen/pages/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/index.vue'),
'schoolmen/pages/login': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/login.vue'),
'agents/components/controls/Location': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/controls/Location.vue'),
'agents/components/page/ExportationDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/page/ExportationDialog.vue'),
'agents/components/page/ProductSubscriptionBadges': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/page/ProductSubscriptionBadges.vue'),
'agents/components/page/RedirectToSchoolmenDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/page/RedirectToSchoolmenDialog.vue'),
'agents/components/page/ResetStudentPasswordDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/page/ResetStudentPasswordDialog.vue'),
'agents/components/page/TableFooterBar': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/components/page/TableFooterBar.vue'),
'schoolmen/components/controls/Location': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/controls/Location.vue'),
'schoolmen/components/page/ExportationDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/page/ExportationDialog.vue'),
'schoolmen/components/page/ProductSubscriptionBadges': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/page/ProductSubscriptionBadges.vue'),
'schoolmen/components/page/ResetStudentPasswordDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/page/ResetStudentPasswordDialog.vue'),
'schoolmen/components/page/TableFooterBar': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/components/page/TableFooterBar.vue'),
'schoolmen/pages/joint_evaluations/Fields': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/Fields.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id].edit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id].edit.vue'),
'schoolmen/pages/joint_evaluations/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/index.vue'),
'schoolmen/pages/joint_evaluations/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/new.vue'),
'schoolmen/pages/schools/SchoolBreadcrumb': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/SchoolBreadcrumb.vue'),
'schoolmen/pages/schools/SchoolPicker': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/SchoolPicker.vue'),
'schoolmen/pages/schools/SchoolTab': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/SchoolTab.vue'),
'schoolmen/pages/schools/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/index.vue'),
'schoolmen/pages/students/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/students/index.vue'),
'agents/pages/profile/balance_records/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/profile/balance_records/index.vue'),
'agents/pages/profile/password/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/profile/password/index.vue'),
'agents/pages/profile/point_records/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/profile/point_records/index.vue'),
'agents/pages/profile/purchases/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/profile/purchases/new.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/EvaluationExporterAnswerDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/EvaluationExporterAnswerDialog.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/EvaluationExporterScoreDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/EvaluationExporterScoreDialog.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/HeaderBreadcrumb': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/HeaderBreadcrumb.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/HeaderExtra': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/HeaderExtra.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/HeaderTitle': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/HeaderTitle.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/Nav': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/Nav.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/index.vue'),
'schoolmen/pages/profile/password/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/profile/password/index.vue'),
'schoolmen/pages/schools/[school_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/index.vue'),
'agents/pages/profile/purchases/[id]/success': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/profile/purchases/[id]/success.vue'),
'agents/pages/territories/[territory_id]/invitations/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/invitations/index.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/Fields': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/Fields.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id].edit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id].edit.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/index.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/new.vue'),
'agents/pages/territories/[territory_id]/orders/Nav': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/orders/Nav.vue'),
'agents/pages/territories/[territory_id]/orders/SearchBar': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/orders/SearchBar.vue'),
'agents/pages/territories/[territory_id]/orders/details': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/orders/details.vue'),
'agents/pages/territories/[territory_id]/orders/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/orders/index.vue'),
'agents/pages/territories/[territory_id]/orders/summary': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/orders/summary.vue'),
'agents/pages/territories/[territory_id]/school_income_stats/SearchBar': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/school_income_stats/SearchBar.vue'),
'agents/pages/territories/[territory_id]/school_income_stats/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/school_income_stats/index.vue'),
'agents/pages/territories/[territory_id]/schools/SchoolBreadcrumb': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/SchoolBreadcrumb.vue'),
'agents/pages/territories/[territory_id]/schools/SchoolPicker': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/SchoolPicker.vue'),
'agents/pages/territories/[territory_id]/schools/SchoolTab': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/SchoolTab.vue'),
'agents/pages/territories/[territory_id]/schools/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/index.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/index.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/homeworks/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/homeworks/index.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/CoursewarePickerDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/CoursewarePickerDialog.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/index.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new.vue'),
'schoolmen/pages/schools/[school_id]/batch_workathons/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/batch_workathons/new.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/index.vue'),
'schoolmen/pages/schools/[school_id]/school_homework_reports/SearchBar': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/school_homework_reports/SearchBar.vue'),
'schoolmen/pages/schools/[school_id]/school_homework_reports/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/school_homework_reports/index.vue'),
'schoolmen/pages/schools/[school_id]/students/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/students/index.vue'),
'schoolmen/pages/schools/[school_id]/textbook_forms/Fields': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/textbook_forms/Fields.vue'),
'schoolmen/pages/schools/[school_id]/textbook_forms/SelectSubjectDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/textbook_forms/SelectSubjectDialog.vue'),
'schoolmen/pages/schools/[school_id]/textbook_forms/[textbook_form_id].edit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/textbook_forms/[textbook_form_id].edit.vue'),
'schoolmen/pages/schools/[school_id]/textbook_forms/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/textbook_forms/index.vue'),
'schoolmen/pages/schools/[school_id]/textbook_forms/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/textbook_forms/new.vue'),
'schoolmen/pages/schools/[school_id]/workathons/SubjectPickerDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/workathons/SubjectPickerDialog.vue'),
'schoolmen/pages/schools/[school_id]/workathons/WorkathonCard': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/workathons/WorkathonCard.vue'),
'schoolmen/pages/schools/[school_id]/workathons/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/workathons/index.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/EvaluationExporterAnswerDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/EvaluationExporterAnswerDialog.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/EvaluationExporterScoreDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/EvaluationExporterScoreDialog.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/HeaderBreadcrumb': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/HeaderBreadcrumb.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/HeaderExtra': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/HeaderExtra.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/HeaderTitle': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/HeaderTitle.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/Nav': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/Nav.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/index.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit.vue'),
'schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/index.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/ClazzHeader': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/ClazzHeader.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/ClazzTab': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/ClazzTab.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/index.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/index.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/new.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/index.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/homeworks/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/homeworks/index.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/CoursewarePickerDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/CoursewarePickerDialog.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/index.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/joint_evaluation_courseware_relations/new.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/events/[id]': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/events/[id].vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/events/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/events/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/school_homework_reports/SearchBar': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/school_homework_reports/SearchBar.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/school_homework_reports/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/school_homework_reports/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/Fields': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/Fields.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/SelectSubjectDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/SelectSubjectDialog.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/[textbook_form_id].edit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/[textbook_form_id].edit.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/textbook_forms/new.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/workathons/SubjectPickerDialog': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/workathons/SubjectPickerDialog.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/workathons/WorkathonCard': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/workathons/WorkathonCard.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/workathons/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/workathons/index.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/studentships/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/studentships/index.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/studentships/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/studentships/new.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teachers/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teachers/new.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/index.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/new.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/students/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/students/new.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/workathons/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/workathons/index.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/edit.vue'),
'agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/joint_evaluations/[evaluation_id]/batch_assign_homeworks/[batch_assign_homework_id]/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/ClazzTab': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/ClazzTab.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/index.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/index.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teachers/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teachers/new.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/students/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/students/new.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/studentships/new.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/new': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/new.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/workathons/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/workathons/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/form': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/form.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/agent_transaction_records/[agent_transaction_record_id]/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/teacherships/[teachership_id]/edit.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/AnswerContent': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/AnswerContent.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/BlockElement': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/BlockElement.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/InlineElement': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/InlineElement.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/QuestionSelect': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/QuestionSelect.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/QuestoinOther': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/QuestoinOther.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperContent': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperContent.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntryHeading': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntryHeading.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntryQuestion': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntryQuestion.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntrySuite': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntrySuite.vue'),
'schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/schoolmen/pages/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/index.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/AnswerContent': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/AnswerContent.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/BlockElement': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/BlockElement.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/InlineElement': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/InlineElement.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/QuestionSelect': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/QuestionSelect.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/QuestoinOther': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/QuestoinOther.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperContent': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperContent.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntryHeading': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntryHeading.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntryQuestion': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntryQuestion.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntrySuite': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/TestPaperEntrySuite.vue'),
'agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/index': () => import('/private/var/folders/48/qb4vw4g51tx73_fkvmh66cd40000gn/T/capsum/Users/ll100/Workspaces/leaf-actions-runner/_work/leaf/leaf/school/school/app/javascript/agents/pages/territories/[territory_id]/schools/[school_id]/clazzes/[clazz_id]/homeworks/[homework_id]/homework_papers/[homework_paper_id]/index.vue'),
}
export const createGetRoutes = (router, withLayout = false) => {
  const routes = router.getRoutes()
  if (withLayout) {
      return routes
  }
  return () => routes.filter(route => !route.meta.isLayout)
}

export function setupLayouts(routes) {
  function deepSetupLayout(routes, top = true) {
    return routes.map(route => {
      if (route.children?.length > 0) {
        route.children = deepSetupLayout(route.children, false)
      }
      
      if (top) {
        // unplugin-vue-router adds a top-level route to the routing group, which we should skip.
        const skipLayout = !route.component && route.children?.find(r => (r.path === '' || r.path === '/') && r.meta?.isLayout)  

        if (skipLayout) {
          return route
        }

        if (route.meta?.layout !== false) {
          return { 
            path: route.path,
            component: layouts[route.meta?.layout || 'default'],
            children: route.path === '/' ? [route] : [{...route, path: ''}],
            meta: {
              isLayout: true
            }
          }
        }
      }

      if (route.meta?.layout) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      return route
    })
  }

    return deepSetupLayout(routes)

}
